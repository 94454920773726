@import '../common-mixin.scss';

#divBetSlipNormal .login-account input {
    width: 90%;
    height: 28px;
    border: 0;
    border-radius: 2px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    padding-left: 8px;
    &:focus {
        background-color: #FFFBE6;
    }
}

#divBetSlipNormal .login-account .login-account-pwinput {
    font-family: sans-serif;
}

#divBetSlipNormal .account-password-content {
    width: 100%;
}

#divBetSlipNormal .account-content {
    width: 100%;
    position: relative;
}

#divBetSlipNormal .accountPlaceholder {
    width: 90%;
    height: 32px;
    position: absolute;
    top: 0px;
    left: 0px;
    border: transparent 3px solid;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 13px;
    color: #555;
   font-size: 13px;
}
#divBetSlipNormal .menu-txt{
    color: #ffffff;
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    display: flex;
}
#divBetSlipNormal {
    z-index: 1;
    width: 100%;
    height: auto;
    background-color: #E7E7E7;
    border-radius: 15px 0 0 15px;
    position: relative;
    overflow: hidden;
    /* left: 1100px; */
    top: -40px;
    float: right;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 34px;
    height: calc(100vh - 52px);
    height: calc(100dvh - 52px);
    min-height: 520px;
  //   max-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    .isEWinPage &{
      top: -44px;
      height: calc(100vh - 52px);
      // max-height: calc(100vh - 52px);
    }
}

#divBetSlipNormal .eod {
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgb(0,0,0,0.3);
    position: absolute;
    border-radius: 15px 0px 0px 15px;
    display: none;
}

#divBetSlipNormal .show {
display:block;
}

#divBetSlipNormal .eod-msg {
margin-top: 144px;
//height: 102px;
padding:24px 12px 24px 12px;
border-radius: 4px;
background-color: #FFFFFF;
margin-left: 12px;
margin-right: 12px;
font-size: 13px;
line-height: 18px;
}

#divBetSlipNormal .login {
/* min-height: 110px; */
min-height: 120px;
border-radius: 15px 0 0px 0px;
padding-bottom: 8px;
}

#divBetSlipNormal .control {
background-color: #FFFFFF;
border-radius: 0px 0px 0 15px;
color: #000000;
height: auto;
margin-top: auto;
display: grid;
/* overflow: hidden; */
transition: all 0.3s linear;
}
#divBetSlipNormal .dialogArea{
    position: relative;
    z-index: 11;
}
.dialogArea.dialogAreaMBShow {
    flex: 1;
    .loginTandC {
        height: 100%;
        display: flex;
        flex-direction: column;
        .loginTandCText {
            flex: 1;
            .tandCTb {
                height: 100%;
            }
        }
    }
}
.dialogAreaMBShow ~ *{
    display: none !important;
}
#divBetSlipNormal .panel {
/* height: calc(100vh - 520px); */
flex: 1;
//   min-height: 280px;
padding-bottom: 24px;
}

#divBetSlipNormal .previewPanel {
//   height: calc(100vh - 455px);
min-height: 280px;
//   padding-bottom: 30px;
}

#divBetSlipNormal .confirmationPanel {
// height: calc(100vh - 545px);
height: 100%;
min-height: 280px;
padding-bottom: 30px;
display: flex;
flex-direction: column;
}

#divBetSlipNormal .close {
display: none;
}

#divBetSlipNormal .close:hover {
color: #DBE6EF;
}

.signIn {
min-width: 84px;
height: 28px;
background: #fecf13;
border-radius: 30px;
display: flex;
flex-direction: row;
align-content: center;
justify-content: center;
align-items: center;
font-size: 13px;
color: #122c68;
font-weight: 500;
cursor: pointer;
-ms-user-select: none;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
margin-top: auto;
&.disabled{
    background-color: #e5e5e5 !important;
    opacity: 1 !important;
}
}

.logout {
background: transparent;
color: #ffffff;
border: 1px solid #ffffff;

}

.GL .logout {
background: transparent;
color: #000000;
border: 1px solid black;

}


#divBetSlipNormal .allup-container {
display: inline-flex;
justify-items: center;
align-items: center;
justify-content: space-between;
align-content: center;
flex-wrap: nowrap;
flex-direction: row;
width: 100%;
padding: 16px 16px;
font-size: 15px;
border-bottom: 1px solid #E1E1E1;

}


#divBetSlipNormal .allup-container>div {
/* width: 40%; */
text-align: right;
}

#divBetSlipNormal .allup-container div input {
width: 80px;
height: 30px;
}


#divBetSlipNormal .allup-container .formula {
    text-align: left;
    font-size: 13px;
}

#divBetSlipNormal .allup-container .addBtn {
border-radius: 30px;
background: #FECF13;
width: 90px;
height: 30px;
border: unset;
}

#divBetSlipNormal .add-slip {
    padding: 12px;
    font-size: 13px;
}


#divBetSlipNormal .control > .add-slip {
    grid-column: 1;
    grid-row: 1;
    align-self: flex-end;
}

.betslip-account{
    font-size: 11px;
  }
.fontS15{
    font-size: 15px;
}
#divBetSlipNormal .userAmount{
    width: 136px;
    .fontS15 {
        min-width: 92px;
        font-size: 15px;
    }
}

#divBetSlipNormal .login-account-input {
    margin-bottom: 8px;
}
#divBetSlipNormal .login-account div:last-child > :first-child {
    // height: 20px;
    // position: relative;
}
#divBetSlipNormal .login>.login-account {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    font-size: 13px;
    line-height: 18px;
    align-items: stretch;
}
#divBetSlipNormal .betslip-pcard{
    width: 40px;
    margin-right: 12px;
    img{
      width: 100%;
    }
  }
  #divBetSlipNormal .login .link-account {
    display: flex;
    // justify-content: center;
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
  }

  #divBetSlipNormal .login .link-account button {
      border: none;
  }

  #divBetSlipNormal .login .link-account{
    >div, >div.menu-txt {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      padding: 0 8px;
      min-width: 70px;
      &:hover{
        text-decoration: underline;
      }
      &:first-of-type {
        padding-left: 0;
      }
    }
  }

  #divBetSlipNormal .login .link-account>div:not(:last-child) {
      border-right: solid white 1px;


  }

  .GL #divBetSlipNormal .login .link-account>div:not(:last-child) {
      border-right: solid #000 1px;

  }

  .GL #divBetSlipNormal .login .link-account .menu-txt {
    color: #000000;
  }

  #divBetSlipNormal .betslip-menu{
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;

    .menu_icon{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;

      .betslip-setting{
        width: 24px;
        height: 24px;
      }
      .logoutDropBtn .dropdown-menu.show {
        inset: 2px -3px auto auto !important;
      }
      .loginDropBtn .dropdown-item:before {
        left: unset;
        right: 4px;
      }
      .btn-settings{
        border:unset;
      }
    }
  }
.login-account .login-help {
    position: absolute;
    right: 24px;
    top: -5px;
}
.en #login-account-input,
.en #login-password-input {
   font-size: 13px;
}
.GL #divBetSlipNormal .login {
    border-bottom: 7px solid #FECF13;
}
#divBetSlipNormal .login-account:first-child {
    color: #fff;
}
.GL #divBetSlipNormal .login-account:first-child {
    color: #000000;
}
#divBetSlipNormal .panel {
    // padding: 0 12px;
    /* overflow-y: auto; */
    .bet-line, .login-answer-container{
        margin: 8px 8px 0;
    }
}

#betslip-panel .oddsCheckbox input[type='checkbox'] {
    display: none;
}

#betslip-panel .oddsCheckbox input[type='checkbox']:checked + span {
    background: #00c06c -19px top no-repeat;
    width: 18px;
    height: 18px;
}

#betslip-panel .oddsCheckbox input[type='checkbox'] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 4px;
    vertical-align: middle;
    background: #fff 0 0 no-repeat;
    cursor: pointer;
    border: 1px solid #999999;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 2px;
    font-size: 1rem;
    padding-left: 4px;
}

#betslip-panel .oddsCheckbox input[type='checkbox']:checked + span:before {
    content: ' ';
    border-bottom: 2px solid #fff;
    width: 5px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    height: 11px;
    border-right: 2px solid #fff;
    display: inline-block;
    color: #fff;
    font-weight: 900;
   font-size: 13px;
    padding: 1px 0 0 3px;
    vertical-align: middle;
    text-align: center;
    margin-top: -10px;
    margin-left: 2px;
}

#divBetSlipNormal .login-incorrect-message-container {
    background-color: #ffff;
    padding: 30px 12px;
    position: relative;
    border-radius: 4px;
    margin: 12px 8px 0;
}
.login-incorrect-message-container div.switch-btn-icon {
    position: absolute;
    right: 6px;
    top: 6px;
    height: 20px;
    width: 20px;
}
.login-answer-container{
    margin-bottom: 24px;
}
.login-answer-container .login-answer-title {
    margin: 12px 0;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
}
.login-answer-container .login-answer-content {
    background-color: #fff;
    padding: 16px 12px 1px;
    border-radius: 4px;
}
.login-answer-content > div {
    width: 100%;
    margin-bottom: 12px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 400;
}
.login-answer-content > .button-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.login-answer-content > .button-box > div {
    width: 100px;
    border-radius: 16px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 400;
}
.login-answer-content > .button-box > .ewallet_upgrade {
    width:105px;
}

// .login-answer-content > .button-box > div:first-child:hover {
//     opacity: 0.8;
// }
.login-answer-content > .button-box > div:first-child {
    color: #fff;
    font-weight: 500;
    margin-right: 12px;
}
.login-answer-content > .button-box > div:last-child:hover {
    background: #e6bb11;
}
.login-answer-content > .button-box > div:last-child {
    background-color: #fecf13;
    height: 32px;
}
.login-answer-content > .ekbaBtnArea {

    &>div:first-child{
        width: 156px;
        background-color: unset;
        padding: 0;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        &:hover{
            opacity: 1;
        }
    }
    &>div:last-child{
        min-width: 125px;
    }
    .leftArea{
        &>div{
            background: #569171;
            color: #fff;
            font-weight: 500;
            height: 32px;
            width: 72px;
            border-radius: 16px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 400;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
.login-answer-content .answer-input input {
    width: 100%;
    height: 32px;
    padding-left: 12px;
   font-size: 13px;
    line-height: 14px;
    font-weight: 400;
    padding-right: 8px;
    border: 1px solid #757575;
    border-radius: 2px;
}
.login-answer-content .forgot-answer {
    cursor: pointer;
    margin-bottom: 24px;
}
#divBetSlipNormal  {
    .login-answer-content .forgot-answer{
        color: #003C84;
    }
    .login-answer-content > .button-box > .leftArea > div{
        background: #003C84;
    }
}
// .HR #divBetSlipNormal  {
//     .login-answer-container .login-answer-title,
//     .login-answer-content .forgot-answer{
//         color: #122C68;
//     }
//     .login-answer-content > .button-box > .leftArea > div{
//         background: #122C68;
//     }
// }
// .FB #divBetSlipNormal  {
//     .login-answer-container .login-answer-title,
//     .login-answer-content .forgot-answer{
//         color: #405A55;
//     }
//     .login-answer-content > .button-box > .leftArea > div{
//         background: #405A55;
//     }
// }
// .LY #divBetSlipNormal  {
//     .login-answer-container .login-answer-title,
//     .login-answer-content .forgot-answer{
//         color: #980B0B;
//     }
//     .login-answer-content > .button-box > .leftArea > div{
//         background: #980B0B;
//     }
// }
.login-answer-content .error-answer {
    color: #d62d2d;
}
.login-answer-content .helpLink {
    color: #003c84;
    cursor: pointer;
}
.last-login-info .last-login-info-title {
    font-weight: 500;
    color: #fecf13;
   font-size: 15px;
    margin-bottom: 3px;
    position: relative;
    top: -5px;
}
.last-login-info > div:not(.last-login-info-title) {
    line-height: 18px;
}
.login-answer-content .forgot-answer:hover {
    text-decoration: underline;
}
div.logout-succ-msg-overlay{
    z-index: 9999 !important;
}
div.logout-succ-msg-m-overlay{
    z-index: 9999 !important;
}
div.logout-succ-msg-content {
    width: 500px;
    height: 436px;
    border-radius: 8px;
}

.logout-succ-msg-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
   font-size: 13px;
}
.logout-succ-msg-container > :first-child {
    color: #003c84;
    font-weight: 500;
   font-size: 15px;
    white-space: pre-line;
    margin-bottom: 8px;
}
.logout-succ-msg-container .succ-msg-content {
    display: flex;
    padding: 15px 0;
}
.logout-succ-msg-container .succ-msg-content > div {
    flex: 1;
}
.logout-succ-msg-container .banner-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.logout-succ-msg-container .message-box {
    padding-right: 15px;
    overflow-y: auto;
    max-height: 285px;
}
.logout-succ-msg-container .banner-box > div {
    width: 209px;
    height: 287px;
    background-color: #ffffff;
}
.logout-succ-msg-container .banner-box > div img {
    width: 100%;
}
.logout-succ-msg-container .close-btn-box {
    height: 32px;
}

.logout-succ-msg-container .close {
    background-color: #6176a0;
    width: 84px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
}
.betslip-container .open-btn-icon {
    background: transparent;
    color: #000;
    transform: rotate(45deg);
    height: 30px;
    width: 30px;
}

.betslip-container .open-btn-icon::after {
    top: calc(50% - 1px);
    left: calc(50% - 8px);
    width: 16px;
    height: 2px;
    background: #000;
}

.betslip-container .open-btn-icon::before {
    content: '';
    position: absolute;
    background-color: #000;
    left: calc(50% - 1px);
    top: calc(50% - 8px);
    width: 2px;
    height: 16px;
}

.betslip-container .disp-no {
    display: none;
}
.betslip-container .disabled {
    color: #ffffff;
    pointer-events: none;
}
.betslip-container .disabled::after {
    background: #ffffff;
}

.betslip-container .disabled::before {
    background-color: #ffffff;
}

.betslip-container .loginCloseAreaCon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.betslip-container .loginCloseArea .open-btn-icon {
    display: none;
}
.betslip-container .loginCloseArea {
    .open-btn-icon::before,
    .open-btn-icon::after {
        background: #fff;
        .GL & {
            background: #000;
        }
    }
}

.betslip-container .dropdown-toggle {
    padding: 0;
}
#betslip-container .speedbet-tab {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    vertical-align: middle;
    line-height: 30px;
    font-weight: bold;
    font-size: 13px;
    min-height: 34px;
}
#betslip-container .speedbet-tab div {
    cursor: pointer;
    width: 100%;
    text-align: center;
}
#betslip-container .speedbet-tab .active {
    border-bottom: #fecf13 solid 4px;
}

.otp-popup-content {
    width: 400px !important;
    // min-height: 445px;
    background-color: white !important;
}

.otp-popup-content.is-mobile-content {
    width: 343px !important;
}
.otp-popup-content.is-mobile-content {
    width: 343px !important;
}
.otp-popup-container {
    padding: 16px;
    color: #000;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    display: flex;
    flex-direction: column;
}

.otp-title {
    color: #003C84;
    font-family: "Noto Sans TC";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 106.667% */
    margin-bottom: 12px;
}

.otp-1 {
    align-self: stretch;
    color: #000;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
}

.otp-2 {
    color: #000;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    margin-top: 4px;
}

.otp-3 {
    color: var(--JC-Primary-Banding-blue, #003C84);
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-decoration-line: underline;
}

.otp-pleaseinput {
    color: #000;
    // text-align: center;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    margin-top: 24px;
}

.otp-didnotreceive {
    overflow: hidden;
    color: var(--JC-Neutrals-Black, #000);
    // text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    margin-top: 36px;
}

.otpauth-bottom {
    text-align: center;
}

.otp-input {
    width: 44px !important;
    height: 44px !important;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    background-color: unset !important;
    border: 1px solid var(--JC-Neutrals-Dark-1, #8B8B8B) !important;
}

.otp-input:focus {
    border-color: #003C84 !important;
}

.otp-input-invalid {
    border: 1px solid var(--JC-State-Supportive-Error, #E10004) !important;
}

.otpauth-button {
    // text-align: center;
    cursor: pointer;
    color: var(--JC-Primary-Banding-blue, #003C84);
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: fit-content;
}

.otpauth-trouble {
    // text-align: center;
    cursor: pointer;
    color: #003C84;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    width: fit-content;
    margin-top: 36px !important;
}
.otpauth-button.resent {
    color: var(--JC-Neutrals-Dark-2, #8B8B8B);
    cursor: unset;
}

.otpauth-button a {
    color: #003C84;
}

.otpauth-resend {
    text-align: center;
    align-self: center;
    justify-self: center;
}
.otpauth-cancel {
    margin: auto;
}

.otpauth-change-mobile {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.otpauth-change-mobile-msg {
    background-color: #F4F4F4;
    padding: 12px;
    border-radius: 8px;
    color: #6A6D73;
    margin-top: 8px;

    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.otp-or-div {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.otp-or-line {
    flex: 1;
    height: 1px;
    background-color: #CCCCCC;
}

.otp-or-text {

}

.otpauth-next-btn-box {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.otpauth-next-btn {
    background-color: #fecf13;
    width: 200px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 16.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #122C68;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
}

.otpauth-next-btn.otp-disabled {
    background-color: #ccc !important;
    color: #fff !important;
    opacity: .5 !important;
    cursor: unset;
}

.otp-invalid-code {
    // align-items: center;
    color: var(--JC-State-Supportive-Error, #E10004);
    // text-align: center;
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    margin-top: 8px;
}

.otp-popup-container {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    }

    input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    }
}

.errorpopup-content {
    width: 400px !important;
    height: fit-content !important;
    background-color: white !important;
    border-radius: 10px;
}

.errorpopup-content.is-mobile-content {
    width: 375px !important;
}

.errorpopup-container {
    padding: 16px;
}

.errorpopup-title {
    font-family: "Noto Sans TC";
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #003C84;
}

.errorpopup-message {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000000;
    margin-top: 12px;
}

.errorpopup-btn-grp {
    margin-top: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .row1,.row2 {
        display: flex;
        justify-content: center;
    }

    .errorpopup-close {
        width: 200px;
        height: 32px;
        font-family:"Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        align-content: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #003C84;
        color: #FFFFFF;
    }
}
.loginerrorpopup-btn-grp {
    margin-top: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .row1,.row2 {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .errorpopup-close {
        width: 120px;
        height: 32px;
        font-family:"Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        align-content: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #003C84;
        color: #FFFFFF;
    }
    .errorpopup-unlock {
        width: 120px;
        height: 32px;
        font-family:"Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        align-content: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(254, 207, 19, 1);
        color: #000;
    }
}

.ekbapopup-close {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 14px;
    cursor: pointer;
}

.ekbapopup-content {
    width: 400px !important;
    height: fit-content !important;
    background-color: white !important;
    border-radius: 10px;
}

.ekbapopup-content.is-mobile-content {
    width: 343px !important;
}

.ekbapopup-container {
    padding: 16px;
}

.ekbapopup-upper {
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.ekbapopup-title {
    font-family: "Noto Sans TC";
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #003C84;
}

.ekbapopup-message {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000;
    margin-top: 12px;
}

.ekbapopup-login-question {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
}

.ekbapopup-answer-input input {
    margin-top: 8px;
    border: 1px solid #757575;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    height: 32px;
    line-height: 14px;
    padding-left: 12px;
    padding-right: 8px;
    width: 100%;
}

.ekbapopup-answer-input.showAnswerErrorMsg input {
    border: 1px solid #E10004;
}

.ekbapopup-error-answer {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #d62d2d;
    margin-top: 8px;
}
.ekbapopup-error-answer > span> a {
    color: #003C84 ;
}
.ekbapopup-forgot-answer {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-top: 16px;
    color: #003C84;
    cursor: pointer;
}

.ekbapopup-btn-grp {
    margin-top: 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .row1,.row2 {
        display: flex;
        justify-content: center;
    }

    .cta_yellow {
        width: 200px;
        height: 32px;
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        align-content: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ekbapopup-cancel {
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #003C84;
        cursor: pointer;
    }
}
.otp-trouble-container{
    gap:8px;
    display:grid;
}
.otp-trouble-message{
    width: Fixed (368px)px;
    height: Hug (50px)px;
    padding: 16px 12px 16px 12px;
    gap: 12px;
    border-radius: 4px;
    background: rgba(219, 230, 239, 0.3);
    box-shadow: 2px 2px 10px 0px rgba(192, 205, 214, 1);
    font-family: Noto Sans TC;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
    color: #000000;
}
.otp-trouble {
    padding: 16px;
    gap:24px;
    display: flex;
    flex-direction: column;
    // gap: 5px;
}
.otp-trouble-group{
    text-align: center;
    display: flex;
    flex-direction: column;
    .cancel-btn {
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #003C84;
        cursor: pointer;
    }
}
.otp-acopening-overlay.popup-overlay {
    z-index: 10000 !important;
}

.otp-acopening-content {
    width: 400.06px !important;
    height: fit-content !important;
    background-color: white !important;
    border-radius: 10px;

    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000000;
}
.otp-acopening-content.is-mobile-content {
    width: 343px !important;
}
.otp-acopening {
    padding: 16px;
    color: #000000;
    // display: flex;
    // flex-direction: column;
    // gap: 5px;
}
.otp-acopening-step {
    display: flex;
    flex-direction: row;
    gap: 6.67px;
}
.otp-acopening-step-index{
    width:16px;
    height:16px;
    padding: 6.67px;
    gap: 6.67px;
    border-radius:66.67px ;
    background: #003C84;
    color: rgba(255, 255, 255, 1);
    align-items: center;
    justify-content: center;
    font-family: Noto Sans;
    font-size: 8.67px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;

}
.otp-acopening-close {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 20px;
    cursor: pointer;
}

.otp-acopening-title {
    font-family: "Noto Sans TC";
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #003C84;
}

.otp-acopening-app-name {
    font-weight: 500;
    margin-top: -10px;
    color: #000000;
}
.otp-acopening-app-hints {
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;

}
.otp-acopening-message {
    margin-top: 12px;
    text-align: center;
    line-height: 18px;
}

img.otp-acopening-qrcode {
    width: 100%;
}

.otp-acopening-qrcode-div {
    height: 144px;
    color: #003C84;

    svg {
        height: 144px;
        width: 144px;
        border: 2px solid;
        padding: 12px;
        border-radius: 8px;
    }

    div {
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        position: relative;
        bottom: 11px;
        span {
            width: fit-content;
            background: white;
            padding-left: 2px;
            padding-right: 2px;
        }
    }
}

.otp-acopening-img-group {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .row1,.row2 {
        display: flex;
        justify-content: center;
    }

    .otp-acopening-logo {
        width: 60px;
        align-self: center;
    }

    .otp-acopening-qrcode {
        width: 144px;
        align-self: center;
    }
}
.otp-acopening-message-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 12px 0px 0 0px;
    list-style: none;
    counter-reset: my-counter;

}
.otp-acopening-step-image{
    width: 148px;
    height: 130px;
    margin-bottom: 8px;
}
.otp-acopening-message-list {
    padding-left: 20px;
    padding-top: 16px;
}
.otp-acopening-message-container >div{
    width: 148px;
    font-family: Noto Sans TC;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    align-self: baseline;

}
.otp-acopening-message-container >div >li:before{

    content: counter(my-counter);
    counter-increment: my-counter;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 12px;
    text-align: center;
    border-radius: 50%;
    font-size: 8.67px;
    background: rgba(38, 85, 204, 1);
    color: #fff;
    margin-right: 8px;
    box-shadow: 0px 1.33px 8px 0px rgba(0, 0, 0, 0.15);

    font-weight: 700;
    padding: 1px;
}

.otp-acopening-btn-group {
    margin-top: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .row1,.row2 {
        display: flex;
        justify-content: center;
    }

    .cta_yellow {
        width: 200px;
        height: 32px;
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        align-content: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cta_blue {
        width: 200px;
        height: 32px;
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        align-content: center;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 60, 132, 1);
        color: rgba(255, 255, 255, 1);
    }

    .cancel-btn {
        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #003C84;
        cursor: pointer;
    }
}

.trustbrowser-content {
    width: 400px !important;
    background-color: white !important;
}

.trustbrowser-content.is-mobile-content {
    width: 343px !important;
}

.trustbrowser-container {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding: 16px;
}

.trustbrowser-title-and-description {
    display: flex;
    gap: 24px;
    flex-direction: column;
}
.trustbrowser-header{
    display: flex;
    gap: 12px;
    flex-direction: column;
}
.trustbrowser-content-container{
    display: flex;
    gap: 12px;
    flex-direction: column;
}
.trustbrowser-title {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #000;
    padding-bottom: 2px;
}
.trustbrowser-dialog-title{
    font-family: "Noto Sans TC";
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #003c84 ;
    padding-bottom: 2px;
}
.trustbrowser-message {
    font-family: "Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000;
}

b.trustbrowser-message {
    font-family: "Noto Sans TC";
    font-weight: 500;
}

.trustbrowser-container2 {
    font-family:"Noto Sans TC";
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    width: Fixed (368px);
    height: Fixed (97px);
    padding: 12px;
    gap: 4px;
    border-radius: 4px;
    background: rgba(219, 230, 239, 0.3);
    box-shadow: 2px 2px 10px 0px rgba(192, 205, 214, 1);
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}
.trustbrowser-active{
    background: rgba(199, 215, 228, 1);
    cursor:default
}

.trustbrowser-btn-group {
    display: flex;
    justify-content: center;
    gap: 10px;

    div {
        width: 100px;
        height: 32px;
        flex-shrink: 0;
        border-radius: 16.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        font-family: "Noto Sans TC";
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
    }
}

.trustbrowser-btn-donttrust {
    background-color: #003C84;
    color: #FFFFFF;
}

.trustbrowser-btn-trust {
    background-color: #FECF13 !important;
    color: #000000 !important;
}
.trustbrowser-btn-next {
    width: 200px !important;
    height: 32px;
    gap: 0px;
    border-radius: 16.5px 0px 0px 0px;

    background: rgba(229, 229, 229, 1);
    color: #FFFFFF;
}
.logout-succ-msg-container .close:hover {
    background-color: #003c84;
}

div .comingSoonLogOutDialog-content {
    height: auto;
}
.comingSoonLogOutDialog-content .ComeSoonLogoutRow {
    margin-bottom: 8px;
}
.comingSoonLogOutDialog-content .close-btn-box {
    margin-top: 20px;
}
div.logout-reminder-content {
    width: 400px;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
}
div.logout-reminder-content .logout-reminder-container {
    height: 100%;
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   font-size: 13px;
}

.logout-reminder-container .title {
   font-size: 15px;
    color: #003c84;
    font-weight: 500;
}
.logout-reminder-container .button-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.logout-reminder-container .button-box > div {
    min-width: 84px;
    padding: 0 15px;
    height: 32px;
    border-radius: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logout-reminder-container .button-box > div:first-child {
    background-color: #6176a0;
    color: #fff;
    margin-right: 20px;
}
.logout-reminder-container .button-box > div:first-child:hover {
    background-color: #003c84;
}
.logout-reminder-container .button-box > div:last-child {
    background-color: #fecf13;
}
.logout-reminder-container .button-box > div:last-child:hover {
    background-color: #e6bb11;
}
.logout-reminder-container .link-notice-btn-area {
    display: flex;
}
.logout-reminder-container .notice-btn {
    min-width: 100px;
    padding: 0 12px;
    height: 32px;
    border-radius: 16px;
    color: #FFFFFF;
    background-color: #6176A0;
    margin-left: 24px;
    margin-right: 24px;
    line-height: 32px;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.logout-reminder-container .notice-btn:hover {
    opacity: 0.8;
}
.logout-reminder-container .confirmBtn {
    background-color: #FECF13;
    color: #122C68;
}
.logout-reminder-container .lnk {
    color: #003C84;
    font-weight: bold;
    cursor: pointer;
}

.logout-reminder-container .chk {
    margin-right:5px;
    vertical-align: middle;
}

.logout-reminder-container .center {
    text-align: center;
}

div.logout-reminder-overlay {
    z-index: 9999 !important;
}

.eKBAanswer-logout-reminder-content .button-box > div:last-child {
    background: #ccc;
}
.eKBAanswer-logout-reminder-content .button-box > div:last-child:hover {
    background-color: #bbb;
}

#betslip-panel .preview-title {
    margin: 15px 0 3px 0;
   font-size: 15px;
    color: #000000;
    padding-left: 12px;
}

#betslip-panel .bet-line {
    background-color: #fff;
    padding: 8px;
    margin-top: 12px;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

#betslip-panel .bet-line .alup-dim {
    position: absolute;
    left: -4px;
    top: 0;
    width: calc(100% + 4px);
    height: 100%;
    border-radius: 4px;
    background-color: #f0ecec;
    opacity: 0.5;
    z-index: 2;
}

#betslip-panel .rc-bet-line {
    border-left: 4px solid #6176a0;
}

#betslip-panel .fb-bet-line {
    border-left: 4px solid #569171;
}

#betslip-panel .bet-line .opacity25{
    opacity: 0.25;
}

#betslip-panel .bet-line .open-btn-icon {
    height: 16px;
    width: 16px;
    margin-left: auto;
}

#betslip-panel .bet-line .title {
    display: flex;
    // justify-content: space-between;
    align-items: center;
}

#betslip-panel .bet-line .content {
   font-size: 15px;
    font-weight: 500;
    padding: 8px 0 8px;
    border-bottom: solid #ddd 2px;
    margin-bottom: 8px;
    line-height: 16px;
    position: relative;
    .resendBetBtnContent{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
       font-size: 13px;
        font-weight: 400;
        margin-top: 12px;
        & > :last-child{
            display: flex;
            height: 29px;
            width: 120px;
            border-radius: 2px;
            overflow: hidden;
            &>div{
                width: 60px;
                color: #fff;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #757575;
                &.active{
                    background-color: #00C06C;
                }
                &:first-child.active{
                    background-color: #f03939;
                }
            }

        }
    }

}
#betslip-panel .bet-line .content.COContent{
    padding-top: 0;
    .betLineContentTop{
        display: flex;
        justify-content: space-between;
        gap: 12px;
        .left{
            padding-top: 8px;
            text-align: left;
            word-break: break-word;
        }
        .right{
            text-align: right;
            min-width: 100px;
            max-width: 100px;
            flex: 1;
            padding-top: 6px;
            .fb-preview-amount{
                display: block;
               font-size: 11px;
                margin-top: 8px;
                font-weight: 400;
                &>span {
                    white-space: nowrap;
                }
                &>div{
                   font-size: 15px;
                    font-weight: 500;
                    margin-top: 4px ;
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .coAmountChangeText{
                /* white-space: pre; */
            }
            .fb-preview-bet{
                position: static;
                &.amountUp{
                    color: #43A53E;
                }
                &.amountDown{
                    color: #E10004;
                }
            }
        }

    .comb_newodds{
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        &.oddsUp{
            color: #43A53E;
            &> span{
                display: inline-block;
                width: 11px;
                margin-left: 3px;
                border: solid  ;
                border-width: 0 5px 8px 5px;
                border-color: rgba($color: #000000, $alpha: 0) rgba($color: #000000, $alpha: 0) rgba($color: #43A53E, $alpha: 1)  rgba($color: #000000, $alpha: 0);
            }
        }
        &.oddsDown{
            color: #E10004;
            &> span{
                display: inline-block;
                width: 11px;
                margin-left: 3px;
                border: solid  ;
                border-width: 8px 5px 0 5px;
                border-color:rgba($color: #D81E06, $alpha: 1) rgba($color: #000000, $alpha: 0) rgba($color: #000000, $alpha: 0)   rgba($color: #000000, $alpha: 0);
            }
        }
    }
    }
}
#betslip-panel .bet-line .content-calcAllup.COContent{
    .betLineContentTop{
        .left>div{
            margin-bottom: 10px;
            > .subTitle{
                margin-top: 0;
            }
        }
    }
}

#betslip-panel .bet-line .preview-content {
    padding-right: 26%;
}

#betslip-panel .bet-line .content .hr-preview-unitbet,
#betslip-panel .bet-line .content .fb-preview-bet {
   font-size: 15px;
    font-weight: 500;
    position: absolute;
    right: 0px;
    bottom: 8px;
}
#betslip-panel .bet-line .content .hr-preview-unitbet {
    line-height: 20px;
}
#betslip-panel .bet-line .content-calcAllup .subText {
   font-size: 11px;
    font-weight: normal;
}
#betslip-panel .bet-line .content-calcAllup .subTitle {
    margin-top: 10px;
}
#betslip-panel .bet-line .content-calcAllup .mgb8 {
    margin-bottom: 8px;
}
#betslip-panel .bet-line .content .banker {
    padding: 4px 0;
    color: #000000;
   font-size: 11px;
    font-weight: normal;
}
#betslip-panel .bet-line .content .composite-name {
    text-decoration: underline;
}

#betslip-panel .bet-line .bet-type-col {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-bottom: 8px;
}
#betslip-panel .bet-line .bet-type-col > :first-child:not(.collapse-content-l) {
   font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    max-width: 186px;
    flex: 1;
    margin-right: 12px;
}
#betslip-panel .bet-line:not(.m6-bet-line, .rc-bet-line) .bet-type-col > :first-child:not(.collapse-content-l){
    color: #405a55;
}
#betslip-panel .rc-bet-line .bet-type-col > :first-child {
    color: #122c68;
}
#betslip-panel .bet-line .bet-type-col .unitbet-input {
    position: relative;
    width: 76px;
    font-size: 15px;
    font-weight: 500;
}
#betslip-panel .fb-bet-line .bet-type-col .unitbet-input div {
   font-size: 15px;
    font-weight: normal;
}
#betslip-panel .bet-line .bet-type-col .unitbet-input div {
   font-size: 11px;
    font-weight: normal;
}
#betslip-panel .bet-line .bet-type-col .unitbet-input .talignR {
    text-align: right;
    margin-bottom: 4px;
}

#betslip-panel .bet-line .bet-type-col .unitbet-input span {
    position: absolute;
    left: 5px;
    top: 9px;
    z-index: 2;
}
#betslip-panel .bet-line .bet-type-col .unitbet-input .flexi {
    top: 28px;
}

#betslip-panel .bet-line .bet-type-col .unitbet-input input {
    width: 100%;
    height: 32px;
    text-align: right;
    padding-left: 16px;
    &:focus {
        background-color: #FFFBE6;
    }
}
#betslip-panel .bet-line .bet-type-col .collapse-content-l {
    display: flex;
    color: #000;
    margin-right: 8px;
    flex: 1;
}

#betslip-panel .rc-bet-line .bet-type-col .collapse-content-r {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 8px;
    color: #000;
}

.betLine-unavailable-text-right {
    text-align: right;
}

.betLine-unavailable-text {
    color: red;
    font-size: 13px;
    text-align: right;
    font-weight: 500;
}
@media screen and (max-width: 1280px){
    .en .bet-line-collapse .betLine-unavailable-text.hasEliminated {
        width: min-content;
    }
}
.flex-between {
    display: flex;
    justify-content: space-between;
}
.betLine-unavailable {
    .betline-odds {
        &.oddsUp .odds-value , &.oddsUp .oddsArrow{
            color: #000000 !important;
        }

        &.oddsDown .odds-value , &.oddsDown .oddsArrow{
            color: #000000 !important;
        }
    }
}

.z-index3 {
    z-index: 3;
}

#betslip-panel.panel .rc-bet-line.bet-line-collapse .bet-type-col {
    align-items: flex-start;
}
#divBetSlipNormal  #betslip-panel.betslipPanelalupModel{
    padding-bottom: 80px ;
}
#betslip-panel.panel .rc-bet-line.bet-line-collapse .bet-type-col .collapse-content-l{
    height: 32px;
    align-items: center;
}
#betslip-panel .bet-line .bet-type-col .collapse-content-l .betline-checkbox {
   font-size: 11px;
    font-weight: 400;
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #ccc;
    align-items: center;
    gap: 8px;
    &>:first-child{
        display: flex;
        align-items: center;
    }
}
#betslip-panel .bet-line-collapse .bet-type-col .collapse-content-l .collapse-betline {
    max-width: 136px;
    flex: 1;
    font-weight: 500;
   font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .specialOdds{
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // width: 50px;
        // display: inline;
    }
}
.betline-odds {
    // display: inline-flex;
    &.oddsUp .odds-value , &.oddsUp .oddsArrow{
        color: #00C06C !important;
    }

    &.oddsDown .odds-value , &.oddsDown .oddsArrow{
        color: #E10004 !important;
    }

    &.oddsDown .oddsArrow{
        transform: translate(0, 1px) rotate(180deg);
    }
    .oddsArrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 10px;
        // transform: translate(0, -2px);
        margin-left: 2px;

    }
}
.odds-changed {
    padding: 4px;
    border-radius: 5px;
    background-color: #FECF13;
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
}
.betline-odds:not(.oddsUp, .oddsDown) {
    .oddsArrow {
        display: none;
    }
}

.odds-change {
    display: flex;
    .title-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

#betslip-panel .fb-bet-line .maxDiv-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 8px;

    .right{
        text-align: right;
        flex: 1;
        div{
           font-size: 15px;
            font-weight: 500;
            margin-top: 4px;
            display: flex;
            justify-content: flex-end;
        }
    }
}

#betslip-panel .bet-line .amount-col {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

#betslip-panel .bet-line .amount-col .left {
    display: flex;
    align-items: center;
}
#betslip-panel .bet-line .amount-col .left > :first-child {
    margin-right: 14px;
    min-height: 18px;
    display: flex;
    align-items: center;
    line-height: 12px;
}
#betslip-panel .bet-line .amount-col .left > .betline-checkbox {
    border-left: 1px solid #ddd;
    padding-left: 14px;
    min-height: 18px;
    &>:first-child{
        margin-right: 8px;
        height: 18px;
    }
}
#betslip-panel .bet-line .small-bet-type-col .unitbet-input,
#betslip-panel .bet-line .amount-col .totalbet-input {
    position: relative;
    width: 76px;
    height: 32px;
   font-size: 15px;
    font-weight: 500;
}
#betslip-panel .bet-line .small-bet-type-col .unitbet-input {
    width: 76px;
}
#betslip-panel .bet-line .small-bet-type-col .unitbet-input span,
#betslip-panel .bet-line .amount-col .totalbet-input span {
    position: absolute;
    left: 5px;
    top: 9px;
}
#betslip-panel .bet-line .small-bet-type-col .unitbet-input input,
#betslip-panel .bet-line .amount-col .totalbet-input input {
    width: 100%;
    height: 100%;
    text-align: right;
    padding-left: 16px;
}
#betslip-panel .bet-line .fb-preview-bettype {
   font-size: 13px;
    font-weight: 500;
    color: #405a55;
}
#betslip-panel .bet-line .hr-preview-bettype {
   font-size: 13px;
    font-weight: 500;
    color: #122c68;
}
#betslip-panel .bet-line .fb-preview-amount {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
}
#betslip-panel .bet-line .amount-col-calcAllup .left > :first-child {
    border-left: 0;
}
#betslip-panel .bet-line .amount-col .left > :first-child span {
    margin: 0 0 0 5px;
   font-size: 15px;
    font-weight: 500;
}
#betslip-panel .bet-line .small-bet-type-col {
    display: flex;
    justify-content: space-between;
   font-size: 13px;
    align-items: center;
}
#betslip-panel .bet-line .amount-col .left .betline-checkbox,
#betslip-panel .bet-line .small-bet-type-col .betline-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}
#betslip-panel .bet-line .amount-col .right {
    text-align: right;
    width: 100px;
    margin-left: 12px;
}

#betslip-panel .bet-line .mult-item-name {
   font-size: 11px;
    font-weight: normal;
    margin-top: 4px;
    margin-bottom: 2px;
}
#betslip-panel .m6-bet-line {
    border-left: 4px solid #cb7b7b;
}
#betslip-panel .rc-bet-line #slunitBet,
#betslip-panel .m6-bet-line #slunitBet {
    width: 76px;
    height: 32px;
    display: flex;
    border-radius: 2px;
    justify-content: space-between;
    box-shadow: none;
    align-items: center;
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #666;
    color: #000;
    font-size: 15px;
}

.ddNoRSpace{
    .dropdown-toggle{
        padding-right: unset !important;
    }
}

#betslip-panel .m6-bet-line #slunitBet {

    justify-content: flex-end;
}
#betslip-panel .rc-bet-line #slunitBet {
   font-size: 15px;
    font-weight: bold;
}
#betslip-panel .content .subText {
   font-size: 11px;
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
}
#betslip-panel .rc-bet-line .bet-type-col .collapse-content-l .betline-checkbox {
    align-items: center;
    gap: 4px;
}
// #betslip-panel .rc-bet-line .bet-type-col .collapse-content-l .betline-checkbox .rc-checkbox input {
//     vertical-align: bottom;
// }
#betslip-panel .m6-bet-line .amount-col .left > :first-child {
    border-left: 0px;
}
#betslip-panel .rc-bet-line .dropdown-toggle::after,
#betslip-panel .m6-bet-line .dropdown-toggle::after {
    content: none;
}
#betslip-panel .rc-bet-line .dropdown-toggle .dd-arrow,
#betslip-panel .m6-bet-line .dropdown-toggle .dd-arrow {
    align-self: center;
    margin-top: -5px;
    border-color: #000;
    padding: 3px;
    margin-left: 8px;
}
#betslip-panel .rc-bet-line .dropdown-toggle .dd-arrow {
    margin-left: 25px;
}
#betslip-panel .rc-bet-line .dropdown-menu.show,
#betslip-panel .m6-bet-line .dropdown-menu.show {
    inset: 3px -2px auto auto !important;
    width: 206px;
    padding: 0px;
    border-radius: 10px;
}
#betslip-panel .rc-bet-line .dropdown-menu.show > a:first-child,
#betslip-panel .m6-bet-line .dropdown-menu.show > a:first-child {
    background-color: #980b0b;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
}
#betslip-panel .rc-bet-line .dropdown-item,
#betslip-panel .m6-bet-line .dropdown-item {
    height: 36px;
    font-size: 13px;
    color: #000;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    &:last-child{
        border-radius: 0 0 10px 10px;
    }
}
#betslip-panel .rc-bet-line .dropdown-item.active,
#betslip-panel .m6-bet-line .dropdown-item.active {
    color: #980b0b;
    background-color: #fff;
}
#betslip-panel .rc-bet-line .dropdown-item:before,
#betslip-panel .m6-bet-line .dropdown-item:before {
    border-color: transparent transparent #980b0b;
    top: -6px;
    border-width: 0 4px 6px;
    right: 16px;
}
#betslip-panel .rc-bet-line .dropdown-item:before {
    border-color: transparent transparent #122c68;
}
#betslip-panel .rc-bet-line .dropdown-menu.show > a:first-child {
    background-color: #122c68;
}
#betslip-panel .rc-bet-line .dropdown-item:before,
#betslip-panel .rc-bet-line .dropdown-item.active,
#betslip-panel .rc-bet-line .dropdown-item:hover {
    color: #122c68;
}
#betslip-panel .m6-bet-line .dropdown-item:hover {
    color: #980b0b;
}
#betslip-panel .bet-line .amount-col .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    div {
       font-size: 15px;
        font-weight: 500;
        margin-top: 4px;
    }
}
#betslip-panel .m6-bet-line .m6-preview-amount {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
}
#divBetSlipNormal .preview-bot-area {
    height: 132px;
    padding: 20px 12px 0 12px;
    background-color: #ffffff;
    border-radius: 0px 0px 0 15px;
   font-size: 15px;
    border-top: 1px solid #e1e1e1;
}
#divBetSlipNormal .preview-dfjs {
    display: flex;
    justify-content: space-between;
}
#divBetSlipNormal .confirmation-header {
    width: 100%;
    height: 90px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
}
#divBetSlipNormal .confirmation-header .fontWB {
    font-weight: bold;
}
#divBetSlipNormal .betslip-confirmation-done {
    display: flex;
    justify-content: center;
    &.resendBetBtn{
        justify-content: space-between;
    }
}

#divBetSlipNormal .confirmation-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    color: #000;
    padding-left: 12px;
    padding-right: 12px;
}
#divBetSlipNormal .confirmation-title * {
    color: #000;
}
#divBetSlipNormal .confirmation-title > div {
    display: flex;
}
#divBetSlipNormal .confirmation-title .confirmation-title-l {
    flex-wrap: wrap;
}
#divBetSlipNormal .confirmation-title .confirmation-title-l > div {
    margin-right: 8px;
    align-items: center;
}
.confirmation-title .confirmation-title-l > div > div {
    margin-right: 3px;
}
#divBetSlipNormal .preview-bot {
    margin-bottom: 37px;
}
#divBetSlipNormal .font13 {
   font-size: 13px;
}
#divBetSlipNormal .font15 {
   font-size: 15px;
    font-weight: 500;
}
#divBetSlipNormal .back {
    border-radius: 20px;
    width: 80px;
    height: 40px;
    border: unset;
    color: #ffffff;
    font-size: 15px;
    .HR & {
        background-color: #6176a0 !important;
    }
    .FB & {
        background-color: #569171 !important;
    }
    .LY & {
        background-color: #cb7b7b !important;
    }
    .GL & {
        background-color: #003c84 !important;
    }
}

#divBetSlipNormal {
    .confirm, .confirm_tr{
        border-radius: 20px;
        background-color: #fecf13;
        border: unset;
        width: 200px;
        height: 40px;
        font-weight: 500;
        color: #122c68;
        font-size: 15px;
    }
}
#divBetSlipNormal .resendDone{
    background-color: #569171;
    width: 76px;
    border-radius: 20px;
    height: 40px;
    border: unset;
    color: #fff;
}
#popup-root .resendDoneDialog-content{
    width: 350px;
    height: 170px;
    background-color: #fff;
    border-radius: 15px;
   font-size: 13px;
    .resendDoneDialogContainer{
        width: 100%;
        height: 100%;
        padding: 24px;
        .title{
            font-size: 24px;
            text-align: center;
            margin-bottom: 24px;
        }
        .content{
            text-align: center;
            margin-bottom: 24px;
        }
        .buttonContainer{
            display: flex;
            justify-content: center;
            &>div{
                width: 100px;
                height: 32px;
                margin-right: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                cursor: pointer;
            }
            &>.cancel{
                background-color: #6176A0;
                color: #fff;
            }
            .confirm{
                background-color: #FECF13;
            }
        }
    }
}
#divBetSlipNormal .disFormula {
    width: 100px;
    height: 32px;
    border: 1px solid #b4b4b4;
    background-color: #e7e7e7;
    border-radius: 2px;
}
#divBetSlipNormal #panelAllupFormula {
    width: 100px;
    border: 1px solid #757575;
    height: 32px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0 6px 12px;
   font-size: 15px;
}

#divBetSlipNormal .allup-container .dropdown-menu {
    max-height: 136px;
    overflow-y: auto;
}

#divBetSlipNormal .allup-container .dropdown-item {
   font-size: 15px;
}

#divBetSlipNormal .allup-container .dd-arrow {
    margin-top: -5px;
}

#divBetSlipNormal .allup-container .dropdown-menu.show {
    inset: 5px 0px auto auto !important;
    min-width: 100%;
}
#divBetSlipNormal .allup-container .dropdown-menu a {
    color: #000000;
}
#divBetSlipNormal .allup-container .unitbet-input {
    position: relative;
    width: 100px;
    height: 32px;
   font-size: 15px;
    font-weight: 500;
}
#divBetSlipNormal .allup-container .unitbet-input div {
   font-size: 11px;
    text-align: right;
    font-weight: normal;
}

#divBetSlipNormal .allup-container .unitbet-input span {
    position: absolute;
    left: 5px;
    top: 9px;
}
#divBetSlipNormal .allup-container .unitbet-input .flexi {
    top: 24px;
}

#divBetSlipNormal .allup-container .unitbet-input input {
    width: 100%;
    height: 100%;
    text-align: right;
    &:focus{
        background-color: #FFFBE6;
    }
}
#divBetSlipNormal .ftInplayDialog {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#divBetSlipNormal .dialogMask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.5;
    border-radius: 15px;
    overflow: hidden;
}
#divBetSlipNormal .ftInplayContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding-bottom: 32px;
   font-size: 13px;
}
#divBetSlipNormal .ftInplayIcon {
    position: relative;
    height: 100px;
}

#divBetSlipNormal .bet-line-collapse .title {
    margin-bottom: 4px;
    >:first-child{
        display: flex;
        align-items: center;
        font-size: 12px;
        span{
            margin: 0 4px;
            line-height: 12px;
        }
    }
}
#divBetSlipNormal .bet-line-collapse .bet-type-col {
    align-items: center;
    margin: 0;
}
#divBetSlipNormal .bet-line-collapse .bet-type-col .collapse-betline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    font-weight: normal;
}
#divBetSlipNormal .rc-bet-line .bet-type-col .collapse-betline {
   font-size: 13px;
    font-weight: bold;
}

#divBetSlipNormal .bet-line-collapse.m6-bet-line .bet-type-col > .collapse-betline {
    width: 60%;
}

#divBetSlipNormal .ftInplayIcon:before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 64px;
    height: 64px;
    background-image: url(../../info/Football/info/images/icon_fb_inplay.png);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: -30px;
    border-radius: 0;

    animation-name: box;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes box {
    0% {
        top: 0px;
        transform: rotate(0deg);
    }
    25% {
        top: -10px;
    }
    50% {
        top: -20px;
        transform: rotate(180deg);
    }
    75% {
        top: -10px;
    }
    100% {
        top: 0px;
        transform: rotate(360deg);
    }
}
#divBetSlipNormal .ftInplayIcon:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 63px;
    left: 32px;
    width: 44px;
    height: 2px;
    background: #000000;
    border-radius: 100%;
    animation-name: shadow;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes shadow {
    0%,
    100% {
        left: -10px;
        width: 20px;
    }
    25%,
    75% {
        left: -15px;
        width: 30px;
        height: 4px;
    }
    50% {
        left: -20px;
        width: 40px;
        height: 6px;
    }
}

#divBetSlipNormal .ftInplayFirText {
    margin-top: 32px;
    margin-bottom: 6px;
}
#divBetSlipNormal .ftInplaySecText {
    margin-bottom: 32px;
}
#divBetSlipNormal .ftInplayProcess {
    border-radius: 20px;
    width: 163px;
    height: 7px;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
}
#divBetSlipNormal .ftInplayProcess .ftInplayPPercent {
    width: 1%;
    height: 7px;
    background-color: #fecf13;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 20px 20px 0;
}

#divBetSlipNormal .confirmation-printBtn {
    min-width: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#divBetSlipNormal .error-overlay {
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    width: inherit;
    z-index: 100;
    background: #d81e06;
    color: #ffffff;
    padding: 5px 1px 5px 40px;
    background-image: url(../../info/Include/images/icon_invalid.png);
    background-position: 12px;
    background-repeat: no-repeat;
    background-size: 20px;
   font-size: 13px;
    line-height: 30px;
    margin-top: 12px;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
}
#divBetSlipNormal .icon-accepted {
    background-image: url(../../info/Include/images/icon_success.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
}
#divBetSlipNormal .icon-rejected {
    background-image: url(../../info/Include/images/eft_rejected.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
}
#divBetSlipNormal .icon-unknown {
    background-image: url(../../info/Include/images/icon_timeout.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 32px;
    height: 32px;
}
.icon-print-black {
    width: 20px;
    height: 20px;
    background-image: url(../../info/Include/images/icon-print-black.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 5px;
    background-repeat: no-repeat;
}
.icon-print-white {
    width: 20px;
    height: 20px;
    background-image: url(../../info/Include/images/icon-print-white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 5px;
    background-repeat: no-repeat;
}
.icon-download-black {
    width: 20px;
    height: 20px;
    background-image: url(../../info/Include/images/download.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 5px;
    background-repeat: no-repeat;
}
.icon-download-white {
    width: 20px;
    height: 20px;
    background-image: url(../../info/Include/images/download-white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-right: 5px;
    background-repeat: no-repeat;
}
.icon-receipt-black {
    width: 24px;
    height: 24px;
    background-image: url(../../info/Include/images/receipt_black.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 24px 24px;
    margin-right: 5px;
    background-repeat: no-repeat;
}
.icon-receipt-white {
    width: 24px;
    height: 24px;
    background-image: url(../../info/Include/images/receipt_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 24px 24px;
    margin-right: 5px;
    background-repeat: no-repeat;
}

#divBetSlipNormal .icon-s {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}
#divBetSlipNormal .text-accepted {
    color: #3fa33a;
}
#divBetSlipNormal .text-rejected {
    color: #f03939;
}
#divBetSlipNormal .text-unknown {
    color: #e6bb11;
}

#divBetSlipNormal .error-overlay .open-btn-icon {
    color: #ffffff;
}

#divBetSlipNormal .error-overlay .open-btn-icon::before,
#divBetSlipNormal .error-overlay .open-btn-icon::after {
    background-color: #ffffff;
}
#divBetSlipNormal .betline-confirmation-header {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
}
#divBetSlipNormal .betline-confirmation-header > div {
    margin-right: 3px;
}
#betslip-panel .dividend12px{
    font-size: 12px !important;
}
#betslip-panel .dividend10px{
    font-size: 12px !important;
}
.allup-add-container {
    grid-column: 1;
    grid-row: 1;
    align-self: flex-end;
    background: white;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0;
    border-radius: 0 0 0 15px;
    z-index: 3;
}

.alert-enter {
    left: 400px;
}
.alert-enter-active {
    left: 0;
    transition: left 1000ms;
}
.alert-exit {
    left: 0;
}
.alert-exit-active {
    left: 400px;
    transition: left 1000ms;
}

#divBetSlipNormal .add-slip>div {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
  &:first-child{
    margin-bottom: 8px;
  }
}

#divBetSlipNormal .add-slip>div:last-child {
  margin-top: 12px;
}


#divBetSlipNormal .add-slip .alupBtn {
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
#divBetSlipNormal .add-slip .alupBtn:hover {
  opacity: 0.8;
}
#divBetSlipNormal .add-slip .alupBack {
  border-radius: 20px;
  width: 80px;
  height: 40px;
  font-size: 15px;
  border: unset;
  color: #fff;
//   background-color:  #569171;
  .HR & {
    background-color:  #6176a0;
  }
  .FB & {
    background-color:  #569171;
  }
  .LY & {
    background-color:  #cb7b7b;
  }
  .GL & {
    background-color:  #003c84;
  }
}

#divBetSlipNormal .add-slip .alupSend {
  background-color: #FECF13;
}

#divBetSlipNormal .add-slip .delete {
  background-image: url(../../info/Include/images/delete-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  width: 76px;
  height: 40px;
  border: unset;
}

.GL #divBetSlipNormal .add-slip .delete:disabled,
.HR #divBetSlipNormal .add-slip .delete:disabled,
.FB #divBetSlipNormal .add-slip .delete:disabled,
.LY #divBetSlipNormal .add-slip .delete:disabled {
  background-color: #b4b4b4;
}

.GL #divBetSlipNormal .delete.background2 {
  background-color: #003c84;
}

.HR #divBetSlipNormal .delete.background2 {
  background-color: #6176a0;
}

.FB #divBetSlipNormal .delete.background2 {
  background-color: #569171;
}

.LY #divBetSlipNormal .delete.background2 {
  background-color: #cb7b7b;
}

#divBetSlipNormal .send.background2 {
  background-color: #FECF13;
  color: #000000;
}

// #divBetSlipNormal .delete.background2:hover {
//   opacity: 0.8;
// }

// #divBetSlipNormal .send.background2:hover {
//   opacity: 0.8;
// }

#divBetSlipNormal .add-slip .send {
  border-radius: 20px;
  border: unset;
  width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: 15px;
}
#divBetSlipNormal .add-slip .alupDimm,
#divBetSlipNormal .add-slip .send:disabled {
  background-color: #b4b4b4;
  color: #FFFFFF;
  pointer-events: none;
}

#divBetSlipNormal .money {
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
}
.StandardPage #divBetSlipNormal  .pcard{
    background-color: #F4F4F5;
    color: #000000;
    .betslip-account{
        color: #000000;
    }
    .logout{
        color: #000000;
        border-color: #000000;
    }
    .link-account > div{
        color: #000000;
        &:not(:last-child) {
        border-right: solid #000 1px;

        }
        span{
            color: #000000;
        }
    }
}


/* bet slip print style --- start */
.divBetSlipNormal-print#divBetSlipNormal {
    display: block;
    position: static;
    height: auto;
    width: 100vw;
}
.divBetSlipNormal-print#divBetSlipNormal .betslip-confirmation-done,
.divBetSlipNormal-print#divBetSlipNormal .printBtn-container > *,
.divBetSlipNormal-print#divBetSlipNormal #login {
    display: none;
}
.divBetSlipNormal-print#divBetSlipNormal #betslip-panel {
    height: auto;
}

.divBetSlipNormal-print#divBetSlipNormal .preview-bot-area {
    border-radius: 0;
    height: 60px;
}
.divBetSlipNormal-print#divBetSlipNormal .betSlipScrollBars>:first-child>:first-child{
    position: static !important;
    margin: 0 !important;
}
.link-notice-btn-area {
    margin-left: auto;
    margin-right: auto;
}

.link-notice-btn {
    width: 200px;
    background-color: #ffcc13;
    border-radius: 16px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
}

.readonly {
    opacity: 0.8;
}

#collapsible-control {
    height: 24px;
    width: 24px;
    background-color: #003579;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 4.8px;
    cursor: pointer;

    svg g path {
        fill: #fff;
    }

    &.expand {
        transform: rotate(180deg);
    }
}

.HR #collapsible-control {
    background-color: #6176A0;
}

.FB #collapsible-control {
    background-color: #4C8666;
}

.LY #collapsible-control {
    background-color: #C47070;
}

.GL #collapsible-control {
    background-color: #003579;
}

.betslip-collapse {
    height: 100px !important;
    #divBetSlipNormal {
        height: 84px !important;
        min-height: auto;
        transition: height 200ms;
        overflow: initial;

        #login {
            min-height: 44px;

            .login-account {
                display: none;
            }

            .betslip-menu {
                margin-top: 16px;

                .menu_icon {
                    display: none;
                }
            }
        }

        .add-slip > div:first-child {
            margin-bottom: 12px;

            .money {
                font-weight: 700;
            }
        }

        .control {
            height: 40px;
            overflow: hidden;
        }

        .speedbet-tab {
            display: none;
        }
    }

    #collapsible-control {
        transform: rotate(0deg);
    }
}

/* bet slip print style --- end */

