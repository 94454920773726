.swiper-slide {
    text-align: center;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
    max-height: 180px;
    .StandardPage.GL & {
        max-height: 274px;
    }
}

@media only screen and (min-width: 1681px) {
    .swiper-slide img {
        max-height: 274px;
    }
}

// .GL .swiper-slide {
//     width: 60%;
// }

.swiper-slide {
    width: 420px;
    .StandardPage.GL & {
        width: 640px;
    }
}

@media only screen and (min-width: 1681px) {
    .swiper-slide {
        width: 640px;
    }
}

@media only screen and (max-width: 1279px) {
    .StandardPage.GL .swiper-slide {
        width: 490px;
    }
}

.swiper-button-prev,
.swiper-button-next {
    background: #fff;
    width: 32px;
    height: 32px;
    border-radius: 25px;
    user-select: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-button-prev {
    background-image: url('../../../../info/Include/images/arrow-left.svg');
}

.swiper-button-next {
    background-image: url('../../../../info/Include/images/arrow-right.svg');
}

.swiper-button-prev::after {
    content: '';
}

.swiper-button-next::after {
    content: '';
}

.GL .swiper-button-prev {
    margin-left: 15%;
}

.GL .swiper-button-next {
    margin-right: 15%;
}

:is(.HR, .FB, .LY) .swiper-button-prev {
    margin-left: 3%;
}

:is(.HR, .FB, .LY) .swiper-button-next {
    margin-right: 3%;
}

// .swiper-button-prev::after,
// .swiper-button-next::after {
//     opacity: 1;
//     font-size: 32px;
//     color: white;
//     position: absolute;
//     font-family: 'slick';
//     top: -1px;
//     user-select:none;
// }

// .swiper-button-prev::after {
//     content: '←';
// }

// .swiper-button-next::after {
//     content: '→';
// }

.swiper-container {
    height: 100%;
    .swiper-custom {
        height: 100%;
    }

    .swiper-slide {
        border-radius: 8px;
        overflow: hidden;

        .swiper-slide-content {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    .bannerPagination {
        display: none;
    }
}


.swiper-slide-prev::after, .swiper-slide-next::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(153 153 153 / 20%);
}

.swiper-pagination-bullet-active {
    .HR & {
        background-color: #173e96;
    }

    .FB & {
        background-color: #00c06c;
    }

    .LY & {
        background-color: #d62d2d;
    }

    .GL & {
        background-color: #003c84;
    }
}

@media only screen and (max-width: 1650px) {
    :is(.HR, .FB, .LY) .swiper-button-prev {
        margin-left: 0%;
    }

    :is(.HR, .FB, .LY) .swiper-button-next {
        margin-right: 0%;
    }

    .GL .swiper-button-prev {
        margin-left: 8%;
    }

    .GL .swiper-button-next {
        margin-right: 8%;
    }
}

@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
        .swiper-container {
            height: auto;
            .bannerPagination {
                width: 100%;
                display: flex !important;
                justify-content: center;
                margin-top: 8px;
            }

            .swiper-slide {
                width: min(80%, 300px) !important;
                // img {
                //     max-height: 128px !important;
                // }
            }

            // .StandardPage.GL .swiper-slide {
            //     width: min(89%, 490px);
            //     img {
            //         max-height: 274px;
            //     }
            // }
        }
        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
}


@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
        .StandardPage.GL .swiper-container .swiper-slide {
            width: min(89%, 490px) !important;
        }
    }
}
